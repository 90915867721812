@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: url("/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: italic;
  src: url("/assets/fonts/Roboto-MediumItalic.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: italic;
  src: url("/assets/fonts/Roboto-BoldItalic.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  src: url("/assets/fonts/Roboto-Italic.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  src: url("/assets/fonts/Roboto-LightItalic.ttf");
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-flow: column;
}

#header {
  flex: 0 1 auto;
}

#content {
  flex: 1 1 auto;
}

#root,
#header,
.popUp {
  max-width: 100%;
  width: 100%;
}

.popUp {
  z-index: 100000;
  max-width: 100%;
}

table td.shrink {
  white-space: nowrap;
}

table td.extend {
  width: 99%;
}

rect.pickupShipment {
  stroke: #00ff00;
  fill: #00ff00;
}

rect.deliverShipment {
  stroke: #ff0000;
  fill: #ff0000;
}

.table-list-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-container {
  margin: 50px;
}

.app-heading {
  margin-bottom: 15px;
  text-align: center;
  font-weight: 100;
}

.autocomplete-container {
  box-shadow: unset !important;
}

.autocomplete-container .input-container {
  position: relative;
}

.autocomplete-container .close-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.autocomplete-container .input-container input {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 0.9375rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #7b8a8b !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.card {
  width: 100% !important;
}

.table-filter-container {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.table-filter-input {
  flex: 1;
  margin-right: 10px;
}

@media (max-width: 1020px) {
  .app-container {
    margin: 20px;
  }
}

@media (max-width: 360px) {
  .table-filter-container {
    flex-direction: column;
  }

  .table-filter-input {
    margin-right: unset;
    margin-bottom: 10px;
  }

  .table-refresh-btn {
    width: 100%;
  }
}

.table-centralised {
  width: unset !important;
  margin-top: 25px;
}
.table-centralised th {
  text-align: left;
  width: 50%;
  padding: 7px;
}
.table-centralised td {
  text-align: right;
  padding: 7px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 440px) {
  .checkboxes-container {
    flex-direction: column;
    align-items: center;
  }

  .checkboxes-container .form-check {
    margin-bottom: 10px;
  }
}

ngb-typeahead-window.dropdown-menu {
  max-height: 240px !important;
  overflow-y: auto;
  right: 0px;
}

ngb-typeahead-window.dropdown-menu > .dropdown-item {
  padding: 14px 15px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
}

ngb-typeahead-window.dropdown-menu > .dropdown-item.active {
  background-color: rgba(158, 158, 158, 0.18);
}

.typeahead-clear-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.text-end {
  text-align: end;
}

.extra-large-modal .modal-dialog {
  max-width: 90vw;
}

.c-pointer {
  cursor: pointer;
}
